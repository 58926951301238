$(function () {
    var isShowing = true;
    var contentBox = $('#laes-mere-content');
    var toggler = $('#laes-mere-toggle');
    var alwaysContent = contentBox.find('> p:first');
    var toggleContent = contentBox.find('> *:not(p:first)');

    if (toggleContent.length > 0) {
        toggleContent.hide();
        contentBox.show();
        toggler.show();
        
        toggler.on('click', function (evt) {
            evt.preventDefault();

            if (isShowing) {
                alwaysContent.attr('style', 'margin-bottom: 0;');
                toggler.text(toggler.attr('data-mere'));
                toggleContent.slideUp();
            } else {
                alwaysContent.removeAttr('style');
                toggler.text(toggler.attr('data-mindre'));
                toggleContent.slideDown();
            }

            isShowing = !isShowing;
            return false;
        });

        toggler.trigger('click');
    }

    //Session timed out dialog is only in the html if the session timed out. Show it if it is there.
    $('#loginSessionModal').modal('show');
});